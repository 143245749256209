import React, { useEffect, useState } from "react";
import IsLoadingHOC from "../../Common/IsLoadingHOC";
import { authAxios } from "../../config/config";
import { toast } from "react-toastify";
import {
  BudgetFormat,
  payloadBudget,
  setFormatDate,
  setReportFormatDate,
} from "../../Helper/helper";
import { saveSubscriptionInvoice } from "../../Redux/Reducers/appSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SubscriptionDetail = (props) => {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleFetchSubscriptionDetail = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get(
        `/stripe/list-stripe-subscription-details?start_date=${startDate}&end_date=${endDate}&page=1&limit=25`
      );
      setLoading(false);
      const resData = response?.data;
      if (resData?.status === 1) {
        setSubscriptionDetails(resData?.data.subscriptions);
      } else {
        toast.error(resData?.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  // useEffect(() => {
  //   handleFetchSubscriptionDetail();
  // }, []);

  useEffect(() => {
    handleFetchSubscriptionDetail();
  }, [startDate, endDate]);

  const handleNavigateInvoice = (data) => {
    handleFetch(data?.taskId);
    dispatch(saveSubscriptionInvoice(data));
  };

  const handleFetch = async (id) => {
    await authAxios()
      .get(`/task/get-specific-task/${id}`)
      .then((response) => {
        const FinalBid = payloadBudget(
          `${response.data.data.finalPrice || response.data.data.budget}`
        );
        sessionStorage.setItem("FinalBid", FinalBid);
        navigate("/subscription-invoice");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(subscriptionDetails);

  return (
    <div className="px-[50px] py-[20px] bg-lightgray area--page pb-0">
      <div className="w-[100%] mx-auto pb-[65px]">
        <div className="flex mb-[30px] gap-[20px] items-center">
          <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
            All Subscriptions
          </h1>
        </div>
        <div className="flex gap-[5px] w-full justify-start md:justify-end">
          <div className="float-left bg-white px-2 rounded-lg border border-bordergray">
            <span className="block">Start Date</span>
            <input
              type="date"
              value={startDate || ""}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </div>
          <div className="float-left bg-white px-2 rounded-lg border border-bordergray">
            <span className="block">End Date </span>
            <input
              type="date"
              value={endDate || ""}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px] h-[100vh] over--scroller area--table">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-lightcolor border-b border-textcolor">
              <tr>
                <th className="p-4 font-[400]">Subscription ID</th>
                <th className="p-4 font-[400] min-w-[100px]">Amount</th>
                <th className="p-4 font-[400]">Currency</th>
                <th className="p-4 font-[400]">Interval</th>
                <th className="p-4 font-[400] min-w-[100px]">Amount Due</th>
                <th className="p-4 font-[400]">Status</th>
                <th className="p-4 font-[400] min-w-[150px]">Created</th>
                <th className="p-4 font-[400]">View Invoice</th>
                {/*   <th className="p-4 font-[400]">Invoice PDF</th>*/}
              </tr>
            </thead>
            <tbody>
              {subscriptionDetails &&
                subscriptionDetails.map((item, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[80px]">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.id}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[200px]">
                      <div className="text-gray-900 flex items-center">
                        <div>
                          {/* {item.plan.amount} */}$
                          {BudgetFormat(`${item.plan.amount / 100}`)}
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[200px]">
                      <div className="text-gray-900 flex items-center">
                        <div>{item.plan.currency}</div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[200px]">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.plan.interval}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[200px]">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {/* {item.latestInvoice.amountDue} */}$
                        {BudgetFormat(`${item.latestInvoice.amountDue / 100}`)}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[100px]">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.latestInvoice.status}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[100px]">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {setReportFormatDate(item.latestInvoice.created)}
                      </p>
                    </td>
                    <td
                      onClick={() => handleNavigateInvoice(item)}
                      // onClick={() => window.open(item.latestInvoice.hostedInvoiceUrl) }
                      className="px-5 py-5 border-b border-gray-200 text-sm max-w-[150px]"
                    >
                      <p className="text-gray-900 whitespace-no-wrap cursor-pointer">
                        View Invoice
                      </p>
                    </td>

                    {/* 
                    <td
                      onClick={() => item.latestInvoice.invoicePdf}
                      className="px-5 py-5 border-b border-gray-200 text-sm min-w-[150px]"
                    >
                      <p className="text-gray-900 whitespace-no-wrap">
                        Download PDF
                      </p>
                    </td>
                    */}
                  </tr>
                ))}

              {/*subscriptionDetails.map((subscription, index) => (
              <tr key={index} className="bg-white border-b hover:bg-gray-50">
                <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[80px]">
                  {subscription.id}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[200px]">
                  {subscription.plan.amount}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[200px]">
                  {subscription.plan.currency}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[100px]">
                  {subscription.plan.interval}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[150px]">
                  {subscription.latestInvoice.amountDue}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[150px]">
                  {subscription.latestInvoice.status}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[150px]">
                  {subscription.latestInvoice.created}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[150px]">
                  <a href={subscription.latestInvoice.hostedInvoiceUrl}>
                    View Invoice
                  </a>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm min-w-[150px]">
                  <a href={subscription.latestInvoice.invoicePdf}>
                    Download PDF
                  </a>
                </td>
              </tr>
            ))*/}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default IsLoadingHOC(SubscriptionDetail);
