import React, { useEffect, useState, useRef } from "react";
import { setReportFormatDate } from "../../Helper/helper";
import { IoMdMore } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
import PopupNewsLetter from "./PopupNewsLetter";
import { authAxios } from "../../config/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../Common/IsLoadingHOC";
import Pagination from "../../Common/Pagination";
import { CSVLink } from "react-csv"; // Import CSVLink for CSV download functionality

const Newsletter = () => {
  const [newsLetterData, setNewsLetterData] = useState([]); // Used for newsletter data
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(25);
  const [totalPosts, settotalPosts] = useState(0);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [csvData, setCsvData] = useState([]); // State for CSV data
  const [isCsvReady, setIsCsvReady] = useState(false); // Control when CSV is ready for download
  const csvLinkRef = useRef(null); // Ref to trigger CSV download
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');



  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const handleOpenPopup = () => {
    setNewsLetterData((prev) => ({
      ...prev,
      model: true,
    }));
  };

  const handleClose = () => {
    setNewsLetterData((prev) => ({
      ...prev,
      model: false,
    }));
  };

  const handleSendNewsLetter = async (payload) => {
    console.log("payload", payload);
    await authAxios()
      .post("/newsletter/send-news-letter", payload)
      .then((response) => {
        const resData = response?.data;
        if (resData?.status === 1) {
          toast.success(resData?.message);
          handleClose();
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };


  const GetNewletter = async () => {
    await authAxios()
      .get(`/newsletter/getsubscribe?page=${currentPage}&limit=${postsPerPage}&search=${searchTerm}&start_date=${startDate}&end_date=${endDate}`)
      .then((response) => {
        const resData = response?.data;
        setNewsLetterData(resData?.data);
        // console.log(resData?.count?.count,"resData?.count")
        settotalPosts(resData?.count?.count)
        if (resData?.status === 1) {
          // toast.success(resData?.message);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };


  useEffect(() => {
    GetNewletter();
    // setcurrentPage(1)
  }, [currentPage, searchTerm, startDate, endDate]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setcurrentPage(1); // Reset to page 1 when searching
  };

  // function myDebounce(call,d){
  //   let timer;
  //   return function(...args){
  //     if(timer) clearTimeout(timer);
  //     timer = setTimeout(()=>{
  //     call(...args)
  //     },d)
  //   }
  // }

function myDebounce(call,d){
  let timer;
   return function(...args)
   {
    if (timer) clearTimeout(timer);
    setTimeout(() => {
      call(...args)
    }, d);

   }
}

  const BetterFunction = myDebounce(handleSearchChange,1500)


  const handleDownloadCSV = async () => {
    try {
      const response = await authAxios()
        .get(`/newsletter/getsubscribe?page=&limit=&search=${searchTerm}&start_date=${startDate}&end_date=${endDate}`)

      const resData = response?.data?.data;

      const csvFormattedData = resData.map((item, index) => ({
        S_No: index + 1,
        Email: item?.email,
        Name: item?.user == null ? "-" : (item?.user?.fname + " " + item?.user?.lname),
        Phone_Number: item?.user == null ? "-" : (item?.user?.phoneno),
        user: item?.user == null ? ("No") : ("yes"),
        Subscribed_Date: setReportFormatDate(item?.subscribedAt)


      }));

      setCsvData(csvFormattedData); // Set CSV data for download
      setIsCsvReady(true); // Mark CSV as ready

      // Trigger CSV download manually
      setTimeout(() => {
        if (csvLinkRef.current) {
          csvLinkRef.current.link.click(); // Programmatically trigger the download
          setIsCsvReady(false); // Reset after download
        }
      }, 0);

    } catch (error) {
      toast.error("Failed to download data.");
    }
  };

  return (
    <div className="px-[50px] py-[20px] bg-lightgray area--page pb-0">
      <div className="w-[100%] mx-auto pb-[65px]">
        <div className="flex mb-[30px] gap-[20px] items-center">
          <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
            NewsLetter
          </h1>
        </div>

        <div className="flex mb-[30px] gap-[20px] gap-y-[10px] md:items-center w-full md:justify-between md:flex-row flex-col-reverse ">
          <div className="filter--search flex">
            <div className="filter--input--search flex items-center bg-white pl-[15px] rounded-[5px] h-[45px] min-h border border-bordergray">
              <img
                src="/assets/images/search-icon.svg"
                className="mr-[5px] search--icon"
              />
              <input
                type="text"
                className="pl-[10px] w-full h-full border-0 outline-none"
                placeholder="Search by email"
                // value={searchTerm}
                // onChange={handleSearchChange}

                onChange={BetterFunction}

                
              />

            </div>
          </div>
          <div className="flex gap-[5px] w-full justify-start md:justify-end">

            <div className="float-left bg-white px-2 rounded-lg border border-bordergray">

              <span className="block">Start Date</span>
              <input type="date" value={startDate || ''} onChange={(e) => {
                setStartDate(e.target.value);
                setcurrentPage(1)

              }} />
            </div>
            <div className="float-left bg-white px-2 rounded-lg border border-bordergray">

              <span className="block">End Date </span>
              <input type="date" value={endDate || ''} onChange={(e) => {
                setEndDate(e.target.value);
                setcurrentPage(1)

              }} />
            </div>

          </div>

          <div className="flex gap-[5px] gap-y-[10px] flex-wrap w-full justify-end">
            {/* <button className="flex items-center max-w-[140px] border border-white w-full justify-center bg-white text-textcolor py-[8px] px-[10px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:border-textcolor hover:text-textcolor h-[45px] font-[600] min-h">
               Total Words: {totalPosts || 0} 
            </button> */}

            {/* <button
              onClick={handleOpenPopup}
              className="flex items-center max-w-[160px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h"
            >
              <FiPlus />
              Add
            </button> */}

            {/* CSV Download Button */}
            {/* <CSVLink
              data={newsLetterData.map((item, index) => ({
                S_No: index + 1,
                Email: item?.email,
                Subscribed_Date: setReportFormatDate(item?.subscribedAt),
              }))}
              filename="newsletter.csv"
              className="flex items-center max-w-[160px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h"
            >
              Download CSV
            </CSVLink> */}

            <button
              onClick={handleDownloadCSV}
              className="flex items-center max-w-[160px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h"
              disabled={isCsvReady} // Disable button while fetching data
            >
              {isCsvReady ? "Preparing CSV..." : "Download CSV"}
            </button>
            {/* Hidden CSVLink to trigger download */}
            <CSVLink
              data={csvData}
              filename="newsletter.csv"
              className="hidden"
              ref={csvLinkRef}
              target="_blank"
            />

          </div>
        </div>

        <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px] h-[100vh] over--scroller area--table">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-lightcolor border-b border-textcolor">
              <tr>
                <th scope="col" className="p-4 w-[100px] font-[400]">
                  S.No.
                </th>
                <th scope="col" className="px-6 py-3 text-left w-[350px] font-[400] ">
                  Email ID
                </th>
                <th scope="col" className="px-6 py-3 text-left w-[350px] font-[400] ">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 text-left w-[350px] font-[400] ">
                  Phone Number
                </th>
                <th scope="col" className="px-6 py-3 text-left w-[350px] font-[400] ">
                  User
                </th>



                <th scope="col" className="px-6 py-3 w-[150px] font-[400] text-left">
                  Subscribed Date
                </th>
              </tr>
            </thead>
            <tbody>
              {newsLetterData &&
                newsLetterData.length > 0 &&
                newsLetterData.map((item, index) => (
                  <tr key={index} className="bg-white border-b hover:bg-gray-50">
                    <td className="px-6 py-4 font-medium text-textcolor max-w-[100px]">
                      {/* {index + 1} */}
                      {currentPage == 1 ? (index + 1) : ((currentPage - 1) * postsPerPage) + (index + 1)}
                    </td>
                    <th className="px-6 py-4 font-medium text-textcolor max-w-[150px] text-left">
                      {/* {item?.email} */}
                      {item?.email?.toLowerCase()}

                    </th>
                    <th className="px-6 py-4 font-medium text-textcolor max-w-[150px] text-left capitalize">
                      {item?.user == null ? (<span style={{ marginLeft: "20px" }} >   -</span>) : (item?.user?.fname + " " + item?.user?.lname)}
                    </th>

                    <th className="px-6 py-4 font-medium text-textcolor max-w-[150px] text-left capitalize">
                      {item?.user == null ? (<span style={{ marginLeft: "20px" }} >   -</span>) : (item?.user?.phoneno)}
                    </th>

                    <th className="px-6 py-4 font-medium text-textcolor max-w-[150px] text-left capitalize">
                      {item?.user == null ? (<span style={{ marginLeft: "0px" }} >No</span>) : ("yes")}
                    </th>



                    <th className="px-6 py-4 font-medium text-textcolor max-w-[150px] text-left capitalize">
                      {setReportFormatDate(item?.subscribedAt)}
                    </th>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPosts={totalPosts}
        paginate={paginate}
        postsPerPage={postsPerPage}
        length={1}
      />


    </div>

  );
};

export default IsLoadingHOC(Newsletter);
